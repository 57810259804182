<template>
  <v-container>
    <breadcrumbs class="mb-4" :items="breadcrumbs"></breadcrumbs>
    <v-sheet class="pa-8 mb-8">
      <div class="text-center">
        <h1 class="mb-5">{{ $t("offerings-books") }}</h1>
      </div>
      <div class="text-center mb-5">
        <p v-for="paragraph in $t('offerings-books-text')" :key="paragraph">
          {{ paragraph }}
        </p>
      </div>
    </v-sheet>
    <v-toolbar class="mb-6">
      <v-text-field
        v-model="query"
        hide-details
        prepend-icon="mdi-magnify"
        single-line
        placeholder="Suche"
        color="accent"
      ></v-text-field>
    </v-toolbar>
    <!-- Progress Indicator while waiting on API call -->
    <v-container v-if="books == null" class="text-center">
      <v-progress-circular indeterminate size="64" class="mt-5" />
    </v-container>
    <!-- Error message if something went wrong -->
    <v-container v-else-if="errorMessage != null">
      <v-alert type="error" max-width="300px" class="mx-auto text-center">{{
        errorMessage
      }}</v-alert>
    </v-container>
    <v-container v-else-if="filteredBooks.length == 0">
      <v-sheet class="pa-4 mb-4 text-center">
        {{ $t("offerings-books-no-results") }}
      </v-sheet>
    </v-container>
    <!-- Books if everything went right -->
    <v-sheet
      v-else
      v-for="(book, index) in this.filteredBooks"
      :key="index"
      class="pa-4 mb-4"
    >
      <v-row>
        <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="2">
          <v-img
            v-if="book.image"
            contain
            :src="'https://api.bauchgefuehle-giessen.de/' + book.image"
            max-height="250px"
          >
          </v-img>
        </v-col>
        <v-col cols="12" md="10">
          <h2>{{ book.title }}</h2>
          <h4>{{ book.author }} ({{ book.release }} - {{ book.publisher }})</h4>
          <p class="mt-2" v-html="book.description"></p>
          <v-divider></v-divider>
          <v-container>
            <v-row class="my-auto">
              <v-chip
                v-if="book.available == true"
                class="ma-1"
                color="green"
                text-color="white"
              >
                <v-icon left>
                  mdi-book-check-outline
                </v-icon>
                {{ $t("offerings-book-available") }}
              </v-chip>
              <v-chip v-else class="ma-1" color="red" text-color="white">
                <v-icon left>
                  mdi-book-cancel-outline
                </v-icon>
                {{ $t("offerings-book-unavailable") }}
              </v-chip>
              <v-spacer></v-spacer>
              <v-btn
                class="ma-1"
                color="accent"
                :href="
                  'mailto:' +
                    mailAddress +
                    '?subject=' +
                    $t('offerings-book-mailSubject', { title: book.title }) +
                    '&body=' +
                    $t('offerings-book-mailBody')
                "
              >
                <v-icon class="mr-1">mdi-book-lock-open-outline</v-icon>
                {{ $t("offerings-book-button") }}
              </v-btn>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-sheet>
  </v-container>
</template>
<script>
import axios from "axios";
import Breadcrumbs from "@/components/Breadcrumbs.vue";

export default {
  name: "books",
  components: {
    Breadcrumbs
  },
  data: () => ({
    mailAddress: "mail@bauchgefuehle-giessen.de",
    books: null,
    query: "",
    errorMessage: null
  }),
  computed: {
    breadcrumbs: function() {
      return [
        {
          text: this.$t("home"),
          disabled: false,
          href: "/"
        },
        {
          text: this.$t("offerings"),
          disabled: true
        },
        {
          text: this.$t("offerings-books"),
          disabled: true
        }
      ];
    },
    // Filter books according to search bar input
    filteredBooks: function() {
      if (this.query == "") return this.books;

      return this.books.filter(book => {
        return this.query
          .toLowerCase()
          .split(" ") // Split query in terms
          .every(
            term =>
              // Check for matches
              book.title.toLowerCase().includes(term) ||
              book.author.toLowerCase().includes(term) ||
              book.publisher.toLowerCase().includes(term) ||
              book.description.toLowerCase().includes(term)
          );
      });
    }
  },
  mounted() {
    axios
      .get("https://api.bauchgefuehle-giessen.de/api/books")
      .then(response => this.parseBooks(response))
      .catch(error => this.parseBooks(error));
  },
  methods: {
    parseBooks(response) {
      if (response.status != 200 || !Array.isArray(response.data.data)) {
        this.errorMessage = this.$t("communication-error");
      } else {
        this.books = response.data.data;
      }
    }
  }
};
</script>
